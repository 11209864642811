<template>
  <div>
    <van-nav-bar title="选择喷漆产品" left-arrow :fixed="true" :z-index="999" @click-left="back" />
    <div style="padding-top:46px;">
      <div class="dinwei">
        <div style="position: relative;">
          <img class="cimg" v-if="active == 0" src="../../assets/img/chetou.png" alt />
          <img class="cimg" v-if="active == 1" src="../../assets/img/chewei.png" alt />
          <img class="cimg" v-if="active == 2" src="../../assets/img/zuo.png" alt />
          <img class="cimg" v-if="active == 3" src="../../assets/img/you.png" alt />
          <img class="cimg" v-if="active == 4" src="../../assets/img/chetou.png" alt />
          <img
            class="bimg"
            v-for="(item,idd) in tulist.mallPaintingInfoDOList"
            :key="idd"
            v-show="item.isOK"
            :src="item.locationUrl"
            alt
          />
          <!--  <img class="bimg" src="../../assets/img/chetou_b.png" alt />-->
        </div>
        <!-- <div style="position: relative;" v-if="active == 1">
          <img class="cimg" src="../../assets/img/chewei.png" alt />
          <img class="bimg" src="../../assets/img/chewei_a.png" alt />
          <img class="bimg" src="../../assets/img/chewei_b.png" alt />
        </div>
        <div style="position: relative;" v-if="active == 2">
          <img class="cimg" src="../../assets/img/zuo.png" alt />
          <img class="bimg" src="../../assets/img/zuo_a.png" alt />
          <img class="bimg" src="../../assets/img/zuo_b.png" alt />
          <img class="bimg" src="../../assets/img/zuo_c.png" alt />
          <img class="bimg" src="../../assets/img/zuo_d.png" alt />
          <img class="bimg" src="../../assets/img/zuo_e.png" alt />
          <img class="bimg" src="../../assets/img/zuo_f.png" alt />
          <img class="bimg" src="../../assets/img/zuo_g.png" alt />
          <img class="bimg" src="../../assets/img/zuo_h.png" alt />
        </div>
        <div style="position: relative;" v-if="active == 3">
          <img class="cimg" src="../../assets/img/you.png" alt />
          <img class="bimg" src="../../assets/img/you_a.png" alt />
          <img class="bimg" src="../../assets/img/you_b.png" alt />
          <img class="bimg" src="../../assets/img/you_c.png" alt />
          <img class="bimg" src="../../assets/img/you_d.png" alt />
          <img class="bimg" src="../../assets/img/you_e.png" alt />
          <img class="bimg" src="../../assets/img/you_f.png" alt />
          <img class="bimg" src="../../assets/img/you_g.png" alt />
          <img class="bimg" src="../../assets/img/you_h.png" alt />
        </div>
        <div style="position: relative;" v-if="active == 4">
          <img class="cimg" src="../../assets/img/chetou.png" alt />
          <img class="bimg" src="../../assets/img/zheng_a.png" alt />
          <img class="bimg" src="../../assets/img/zheng.png" alt />
          <img class="bimg" src="../../assets/img/zheng.png" alt />
        </div>-->
      </div>
      <van-tabs v-model="active" style="flex-basis: 20%;overflow: hidden;" @change="qiehuan">
        <van-tab :title="item.plocation" v-for="(item,idx) in list" :key="idx">
          <div class="dd">
            <div
              class="cdd"
              v-for="(it,index) in item.mallPaintingInfoDOList"
              :key="index"
              @click="tianjia(it)"
              :style="{color:(it.isOK?'#ef3085':'#666'),background:(it.isOK?'#fef7f7':'#fff'),}"
            >
              <span>{{it.location}}</span>
              <!-- <span class="jia">+</span> -->
            </div>
          </div>
        </van-tab>
      </van-tabs>
      <div class="xuan">
        <div class="h1">
          <span class="left">已选:</span>
          <span class="right">
            共
            <span style="color: red;">{{num}}</span> 个标准面
          </span>
        </div>
        <div class="dd">
          <div class="odd" v-for="(it,index) in selected" :key="index">
            <div>{{it.location}}</div>
            <span class="biaozhun">{{it.standards}} 标准面</span>
            <!-- <span class="jian" @click="shanchu(it,index)">×</span> -->
            <img class="jian" @click="shanchu(it,index)" src="../../assets/img/guan.png" alt />
          </div>
        </div>
      </div>
    </div>
    <div style="padding-left: 12px;font-size: 16px;margin-top: 10px; " v-if="form.details">商品详情：</div>
    <van-row class="msgHtmlBox ql-snow ql-editor" v-html="form.details"></van-row>
    <div style="height: 60px;"></div>
    <van-popup v-model="show" closeable position="bottom" :style="{ height: '70%' }">
      <div class="qi_h1">
        <img class="gaoduan" v-if="radio == 1" src="../../assets/img/gaoduan.jpg" alt />
        <img class="gaoduan" v-if="radio == 0" src="../../assets/img/biaozhun.jpg" alt />
        <div class="jiage">
          折扣价
          <span style="font-size: 13px;">￥</span>
          <span style="font-weight: bold;font-size: 20px;">{{zhe}}</span>起
          <del class="zhe">￥{{zong}}</del>
        </div>
      </div>
      <div class="xuan_h">选择油漆产品</div>
      <div class="xuanzhe">
        <div
          class="xuandd"
          v-for="(it,index) in chanlist"
          :key="index"
          @click="tohuan(it)"
          :class="it.cid == radio?'racitv':''"
        >
          <div>
            <span class="left">{{it.name}}</span>
            <del class="right" style="font-size: 12px;color: #666;">￥{{it.price}}</del>
          </div>
          <div class="biaozhunc">￥{{it.activeprice}}起/标准面</div>
        </div>
      </div>
      <div class="xuan_h" style="margin-top: 35px;">数量</div>
      <div class="mian_shu">{{num}}个标准面</div>
      <van-goods-action>
        <van-goods-action-button type="danger" text="确认" @click="todingdan" />
      </van-goods-action>
    </van-popup>
    <van-goods-action>
      <van-goods-action-icon icon="phone-o" text="电话咨询">
        <a :href="'tel:'+shangjia.tel1">联系门店</a>
      </van-goods-action-icon>
      <van-goods-action-icon icon="service-o" text="在线客服" @click />
      <van-goods-action-button type="danger" text="选产品" @click="xuantanchuan" />
    </van-goods-action>
    <!-- onClickButton -->
  </div>
</template>

<script>
import userModel from "../../api/user";

export default {
  components: {},
  data() {
    return {
      list: [],
      chanlist: [],
      selected: [],
      tulist: {},
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")),
      active: 0,
      num: 0,
      radio: 1,
      zong: "",
      zhe: "",
      paintPrice: "",
      form:{},
      show: false
    };
  },
  methods: {
    //跳转 订单确认
    todingdan() {
      console.log("4656465");

      this.$router.push({
        path: "spray_order"
      });

      let list = {
        mallPaintingInfoDOList: this.selected,
        num: this.num,
        paintType: this.radio,
        paintPrice: this.paintPrice,
        orderTotal: this.num * this.paintPrice,
        fuwu: this.num * this.paintPrice
      };
      sessionStorage.setItem("penqi", JSON.stringify(list));
    },
    //添加
    tianjia(item) {
      if (!item.isOK) {
        item.isOK = true;
        this.selected.push(item);
      }
      this.addbiaozhun();
    },
    //切换油漆产品
    tohuan(it) {
      console.log(it);
      this.radio = it.cid;
      this.paintPrice = it.activeprice;
      this.zong = this.num * it.price;
      this.zhe = this.num * it.activeprice;
    },
    //弹窗选择
    xuantanchuan() {
      if (this.num == 0) {
        this.$toast("请先选择喷漆位置！");
        return;
      }
      userModel
        .peiqiprice()
        .then(e => {
          this.chanlist[0] = {
            cid: 1,
            name: "高端漆",
            price: e.data.hprice,
            activeprice: e.data.haprice
          };
          this.chanlist[1] = {
            cid: 0,
            name: "标准漆",
            price: e.data.sprice,
            activeprice: e.data.saprice
          };
          this.paintPrice = this.chanlist[0].activeprice;
          this.zong = this.num * this.chanlist[0].price;
          this.zhe = this.num * this.chanlist[0].activeprice;
          this.show = true;
        })
        .catch();
    },
    //计算标准面
    addbiaozhun() {
      var num = 0;
      this.selected.forEach((item, i) => {
        num += item.standards;
      });
      this.num = num;
      console.log(num);
    },
    //删除
    shanchu(item, idx) {
      item.isOK = false;
      console.log(item);
      this.selected.splice(idx, 1);
      this.addbiaozhun();
    },
    qiehuan(name, title) {
      console.log(name);
      this.tulist = this.list[name];
      console.log(title);
    },
    onLoad() {
      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "加载中..."
      });
      userModel
        .peiqilist()
        .then(e => {
          loading.clear();
          this.list = e.data;
          this.tulist = e.data[0];
 
        })
        .catch(() => loading.clear());
    },
    xiangqin() {
      userModel
        .penqiprice()
        .then(e => {
          this.form = e.data;
        })
        .catch();
    },
    back() {
      this.$router.go(-1);
    }
  },
  created() {
    this.onLoad();
    this.xiangqin()
  },
  activated() {
    console.log("执行了");
    this.show = false;
  }
};
</script>
<style>
.van-tab {
  width: 20%;
  flex-basis: 20% !important;
}
.ql-editor p img {
 width: 100%;
}
</style>
<style lang="less" scoped>
.cimg {
  width: 100%;
  position: absolute;
  top: 0;
  z-index: -1;
  height: 207px;
}
.van-tabs__content {
  margin: 15px 0;
  border-bottom: 1px solid #f5f5f5;
}
.dd {
  width: 100%;
  font-size: 13px;
  color: #666;
  margin-top: 15px;
  min-height: 45px;
}
.xuanzhe {
  width: 100%;
  font-size: 12px;
  color: #666;
  margin-top: 5px;
  overflow: hidden;
}
.xuan {
  overflow: hidden;
  margin-top: 15px;
  border-top: 1px solid #f5f5f5;
}
.jia {
  position: absolute;
  top: 0;
  right: 0;
  width: 16px;
  height: 16px;
  line-height: 12px;
  border: 1.5px solid red;
  border-radius: 8px;
  color: red;
  display: inline-block;
  font-weight: bold;
}
.jian {
  position: absolute;
  top: 0;
  right: 0;
  width: 22px;
  height: 22px;
  // border-radius: 8px;
  display: inline-block;
  font-weight: bold;
}
.h1 {
  overflow: hidden;
  padding: 6px 12px 0 12px;
  font-size: 14px;
}
.cdd {
  position: relative;
  float: left;
  margin-left: 4%;
  height: 40px;
  line-height: 40px;
  width: 28%;
  text-align: center;
  border: 1px solid #f5f5f5;
  border-radius: 10px;
}
.odd {
  position: relative;
  float: left;
  margin-left: 4%;
  height: 45px;
  line-height: 35px;
  width: 28%;
  text-align: center;
  border: 1px solid #f5f5f5;
  border-radius: 10px;
}
.xuandd {
  position: relative;
  float: left;
  margin-left: 4%;
  height: 50px;
  width: 27%;
  font-size: 13px;
  border: 1px solid #f5f5f5;
  border-radius: 10px;
  padding: 4px 8px;
}
.qi_h1 {
  position: relative;
  padding: 20px 12px 0px 12px;
}
.xuan_h {
  margin-top: 20px;
  font-size: 14px;
  padding: 0 12px;
  font-weight: bold;
}
.jiage {
  position: absolute;
  bottom: 0;
  left: 0;
  margin-left: 140px;
  margin-bottom: 5px;
  color: #ee0a24;
  font-size: 14px;
}
.zhe {
  // margin-top: 5px;
  color: #666;
  font-size: 13px;
  // margin-left: 2px;
}
.gaoduan {
  width: 100px;
  height: 100px;
  vertical-align: text-bottom;
}
.biaozhun {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 18px;
  line-height: 18px;
  font-size: 10px;
  transform: scale(0.8);
  color: mediumslateblue;
}
.mian_shu {
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  font-size: 12px;
  display: inline-block;
  padding: 2px 12px;
  margin-left: 12px;
  background: #fef7f7;
  color: #ef3085;
}
.racitv {
  color: rgb(239, 48, 133);
  background: rgb(254, 247, 247);
}
.biaozhunc {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 18px;
  line-height: 18px;
  font-size: 11px;
  margin-left: 8px;
  margin-bottom: 4px;
  // transform: scale(0.8);
  // color: #666;
}
.cdd:nth-of-type(n + 4) {
  margin-top: 8px;
}
.odd:nth-of-type(n + 4) {
  margin-top: 8px;
}
.gang {
  font-size: 14px;
  color: #666;
}
.van-goods-action-button--last {
  border-radius: 20px;
}
.bimg {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 9;
  height: 207px;
}
.dinwei {
  position: relative;
  height: 207px;
}
.van-cell {
  color: #323233;
  font-size: 14px;
}
.ql-editor {
  margin: 2px 15px;
  overflow: hidden;
  font-size: 14px;
  // margin-top: 15px;
}

</style>
<style lang="less">
.van-tabs__line {
  bottom: 20px;
}
.ql-editor p img {
  width: 100%;
  outline-width: 0px;
  vertical-align: top;
}
</style>
<style scoped>
.msgHtmlBox >>> img {
  width: 100%;
}
/* .msgHtmlBox >>> strong {
  font-weight: bold;
} */
</style>